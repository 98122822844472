import React, { useRef, useState } from "react"
import axios from "axios"

function Contact() {
    const form = useRef()
    const [isLoading, setIsLoading] = useState(false)

    const sendEmail = async (event) => {
        setIsLoading(true)
        event.preventDefault()
        const formData = new FormData(event.target)
        const formValues = Object.fromEntries(formData.entries())

        const reqData = {
            to: formValues.useremail,
            name: formValues.username,
            number: "",
            company: "",
            from: "support@datumailabs.com",
            subject: formValues.subject,
            message: formValues.message,
        }

        const result = await axios.post(
            "https://rwbose7loc.execute-api.us-east-1.amazonaws.com/datum-rest-api/spacecoasttnd_sendemail",
            reqData
        )
        console.log(result.data)

        setIsLoading(false)

        event.target.reset()
    }

    return (
        <div className="container py-24 mx-auto md:px-6 bg-[#f1f0eb]">
            <section className="mb-32">
            <div className="text-center font-medium mb-6 mb-lg-0p">
                                <div className="flex items-center space-x-1">
                                    <a href="#" className="text-neutral-500">
                                        FAQs
                                    </a>
                                    <span className="text-neutral-500">|</span>
                                    <a href="mailto:sales@spacecoastdoor.com" className="text-neutral-500">
                                        Sales
                                    </a>
                                    <span className="text-neutral-500">|</span>
                                    
                                    </div>
                            </div>
                <div className="flex flex-wrap">

                    <div className="mb-10 p-2 pb-10 lg:pb-0 w-full shrink-0 grow-0 basis-auto md:mb-0 md:w-6/12 md:px-3 lg:px-6">
                        <h2 className="mb-6 text-3xl font-bold">Contact us</h2>
                        <p className="mb-2 text-neutral-500">
                            6952 Sonny Dale Dr, STE &apos;B&apos;, Melbourne, FL 32904, United States 321.426.7585
                        </p>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3519.659053478486!2d-80.67320672506294!3d28.095941008246296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88de0e57c7b50eb1%3A0xefc7eaf4fe46f3e5!2sSpace%20Coast%20Trim%20%26%20Door!5e0!3m2!1sen!2sin!4v1699751385933!5m2!1sen!2sin"
                            style={{ border: 0, width: "100%", height: "70%" }}
                            allowfullscreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                    <div className="mb-12 p-2 w-full md:mb-0 md:w-6/12 md:px-3 lg:px-6 text-neutral-500">
                        <form ref={form} name="contactus" onSubmit={sendEmail}>
                            <div className="relative mb-6">
                                <input
                                    type="text"
                                    className="peer block min-h-[auto] w-full rounded border-0 bg-white py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear"
                                    name="username"
                                    placeholder="Name"
                                />
                            </div>
                            <div className="relative mb-6">
                                <input
                                    type="email"
                                    className="peer block min-h-[auto] w-full rounded border-0 bg-white py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200"
                                    name="useremail"
                                    placeholder="Email address"
                                />
                            </div>
                            <div className="relative mb-6">
                                <input
                                    type="text"
                                    className="peer block min-h-[auto] w-full rounded border-0 bg-white py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200"
                                    name="subject"
                                    placeholder="Subject"
                                />
                            </div>
                            <div className="relative mb-6">
                                <textarea
                                    className="peer block min-h-[auto] w-full rounded border-0 bg-white py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear"
                                    name="message"
                                    rows="3"
                                    placeholder="Your message"
                                ></textarea>
                            </div>
                            <button
                                type="submit"
                                data-te-ripple-init
                                data-te-ripple-color="light"
                                className="mb-6 inline-block w-full rounded bg-yellow-400 px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-yellow-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                                disabled={isLoading}
                            >
                                {isLoading && 'Sending...'}
                                {(!isLoading) && 'Submit Message'}
                            </button>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contact
